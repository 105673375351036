import { Theme } from '@mui/material'
import Box from '@mui/material/Box'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useRouter } from 'next/router'
import { useCurrentPositionMarker } from './CurrentPositionMarker'
import { useStoreLocatorMap } from './StoreLocatorMapLoader'
import { usePosition } from './helpers/usePosition'
import { useStoreMarkers } from './helpers/useStoreMarkers'
import { useStores } from './helpers/useStores'

export function SimpleMapView() {
  const { ref, map } = useStoreLocatorMap()
  const { position } = usePosition()
  const { stores } = useStores(position)
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'))

  useCurrentPositionMarker(position)

  const router = useRouter()
  const { asPath } = router
  const urlPath = asPath.split('/winkel/')

  useStoreMarkers(stores, isMobile, undefined, map, true, urlPath)

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
      }}
    >
      <Box ref={ref} id='map' sx={{ height: '100%' }} />
    </Box>
  )
}
