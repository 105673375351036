import {
  Scroller,
  ScrollerButton,
  ScrollerDots,
  ScrollerProvider,
} from '@graphcommerce/framer-scroller'
import { Image } from '@graphcommerce/image'
import { iconChevronLeft, iconChevronRight, responsiveVal, IconSvg } from '@graphcommerce/next-ui'
import { Box, SxProps, Theme } from '@mui/material'

export type Props = {
  images: string[]
  retailStoreId: string | undefined | null
  sx?: SxProps<Theme>
}

export function ImageSlider(props: Props) {
  const { images, retailStoreId, sx = [] } = props

  return (
    <Box sx={[{ position: 'relative' }, ...(Array.isArray(sx) ? sx : [sx])]}>
      <ScrollerProvider>
        <Scroller hideScrollbar sx={{ gridAutoColumns: '100%' }}>
          {images.map((image) => (
            <Image
              src={image ?? ''}
              layout='fill'
              key={`${retailStoreId}-${image}`}
              sx={{ objectFit: 'cover' }}
            />
          ))}
        </Scroller>
        <Box
          sx={{
            position: 'absolute',
            left: 0,
            top: `50%`,
            transform: 'translateY(-50%)',
          }}
        >
          <ScrollerButton
            direction='left'
            sx={{
              display: { xs: 'flex' },
              width: { xs: '24px', sm: '44px' },
              height: { xs: '48px', sm: '88px' },
              boxShadow: 'none',
              transform: 'none',
              borderRadius: { xs: '0 24px 24px 0px', sm: '0 44px 44px 0px' },
            }}
          >
            <IconSvg
              src={iconChevronLeft}
              sx={(theme) => ({
                position: 'absolute',
                color: theme.palette.primary.main,
                fontSize: responsiveVal(25, 62),
                strokeWidth: { xs: 3, sm: 2, lg: 1.2 },
              })}
            />
          </ScrollerButton>
        </Box>
        <Box
          sx={{
            position: 'absolute',
            right: 0,
            top: `50%`,
            transform: 'translateY(-50%)',
          }}
        >
          <ScrollerButton
            direction='right'
            sx={{
              display: { xs: 'flex' },
              width: { xs: '24px', sm: '44px' },
              height: { xs: '48px', sm: '88px' },
              boxShadow: 'none',
              transform: 'none',
              borderRadius: { xs: '24px 0 0 24px', sm: '44px 0 0 44px' },
            }}
          >
            <IconSvg
              src={iconChevronRight}
              sx={(theme) => ({
                position: 'absolute',
                color: theme.palette.primary.main,
                fontSize: responsiveVal(25, 62),
                strokeWidth: { xs: 3, sm: 2, lg: 1.2 },
              })}
            />
          </ScrollerButton>
        </Box>
        <Box
          sx={(theme) => ({
            display: 'flex',
            justifyContent: 'center',
            position: 'absolute',
            bottom: theme.spacings.sm,
            left: 0,
            zIndex: 2,
            width: '100%',
          })}
        >
          <ScrollerDots
            sx={(theme) => ({
              '& .ScrollerDot-dot': {
                width: '10px',
                height: '10px',
                margin: '0 5px',
              },
              '& .ScrollerDot-circle': {
                background: theme.palette.background.paper,
                opacity: '1!important',
              },
              '& .ScrollerDot-circle.active': {
                background: theme.palette.primary.main,
              },
            })}
          />
        </Box>
      </ScrollerProvider>
    </Box>
  )
}
