import { PageOptions } from '@graphcommerce/framer-next-pages'
import { ScrollerProvider, Scroller } from '@graphcommerce/framer-scroller'
import { StoreConfigDocument } from '@graphcommerce/magento-store'
import { GetStaticProps, iconCheckmark, IconSvg, PageMeta, Row } from '@graphcommerce/next-ui'
import { Trans } from '@lingui/react'
import {
  Box,
  Grid,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material'
import { GetStaticPaths } from 'next'
import { LayoutNavigation, LayoutNavigationProps } from '../../components'
import Fotoservice from '../../components/Icons/Fotoservice.svg'
import Gezondheidscheck from '../../components/Icons/Gezondheidscheck.svg'
import Parfumerie from '../../components/Icons/Parfumerie.svg'
import Pasfoto from '../../components/Icons/Pasfoto.svg'
import Schoonheidssalon from '../../components/Icons/Schoonheidssalon.svg'
import Servicepunt from '../../components/Icons/Servicepunt.svg'
import Stomerij from '../../components/Icons/Stomerij.svg'

import { ImageSlider } from '../../components/ImageSlider/ImageSlider'
import { LayoutDocument } from '../../components/Layout/Layout.gql'
import { GetRetailStoresDocument } from '../../components/StoreLocator/GetRetailStores.gql'
import { RetailStoreFragment } from '../../components/StoreLocator/RetailStore.gql'
import { SimpleMapView } from '../../components/StoreLocator/SimpleMapView'
import { StoreLocatorMapLoader } from '../../components/StoreLocator/StoreLocatorMapLoader'
import { retailStoreDocument, retailStoreQuery } from '../../graphql/RetailStore.gql'
import { RetailStoresPathsDocument } from '../../graphql/RetailStoresPaths.gql'
import { graphqlSsrClient, graphqlSharedClient } from '../../lib/graphql/graphqlSsrClient'

type Props = retailStoreQuery
type RouteProps = { url: string }
type GetPageStaticPaths = GetStaticPaths<RouteProps>
type GetPageStaticProps = GetStaticProps<LayoutNavigationProps, Props, RouteProps>

function nonNullable<T>(value: T): value is NonNullable<T> {
  return value !== null && value !== undefined
}

function getWeekdayString(day: number) {
  const weekday = [
    <Trans id='Monday' />,
    <Trans id='Tuesday' />,
    <Trans id='Wednesday' />,
    <Trans id='Thursday' />,
    <Trans id='Friday' />,
    <Trans id='Saturday' />,
    <Trans id='Sunday' />,
  ]
  return weekday[day - 1]
}

function RetailStorePage(props: { retailStore: RetailStoreFragment }) {
  const { retailStore } = props
  const {
    name,
    description,
    path,
    street,
    postcode,
    city,
    image_paths,
    short_description,
    relation_nr,
    phone,
    opening_times,
    services,
  } = retailStore
  const title = name ?? ''
  const longDescription = description ?? ''
  const storePath = path?.replace(/-/g, '+')
  const storeRouteLink = `https://google.com/maps?daddr=${storePath}+${street}+${postcode}+${city}`

  const iconNames = {
    fotoservice: Fotoservice,
    gezondheidscheck: Gezondheidscheck,
    parfumerie: Parfumerie,
    "pasfoto's": Pasfoto,
    schoonheidssalon: Schoonheidssalon,
    servicepuntpakket: Servicepunt,
    servicepuntpostnl: Servicepunt,
    stomerij: Stomerij,
  }

  return (
    <Box
      sx={(theme) => ({
        backgroundColor: theme.palette.primary.contrastText,
        pt: theme.spacings.lg,
      })}
    >
      <PageMeta title={title} metaDescription={title} />
      <Row sx={(theme) => ({ mb: theme.spacings.sm })}>
        <Grid container sx={{ alignItems: 'center' }} spacing={5}>
          <Grid item md={6} xs={12} key={`${storePath}-1`}>
            <Typography variant='h2' component='h1'>
              {title}
            </Typography>
            {short_description}
          </Grid>
          {image_paths && image_paths?.length > 0 && (
            <Grid item md={6} xs={12} key={`${storePath}-2`}>
              {image_paths && (
                <ImageSlider images={image_paths?.filter(nonNullable)} retailStoreId={storePath} />
              )}
            </Grid>
          )}
        </Grid>
      </Row>

      <Row sx={(theme) => ({ pb: theme.spacings.sm, mb: theme.spacings.md })}>
        <Grid container spacing={5}>
          <Grid item lg={3} sm={6} xs={12}>
            <Typography variant='h4'>
              <Trans id='Subscribe to the newsletter' />
            </Typography>
            <Box id='sqzl-newsletter-storeform-wrapper' data-storeref={relation_nr} />
          </Grid>
          <Grid item lg={3} sm={6} xs={12}>
            <Typography variant='h4'>
              <Trans id='Contact details' />
            </Typography>
            <Typography variant='body1'>{name}</Typography>
            <Typography variant='body1'>{street}</Typography>
            <Typography variant='body1'>
              {postcode} {city}
            </Typography>
            <Typography variant='body1'>{phone}</Typography>
            <Link target='_blank' href={storeRouteLink}>
              <Typography variant='body1'>
                <Trans id='Directions' />
              </Typography>
            </Link>
          </Grid>
          <Grid item lg={3} sm={6} xs={12}>
            <Typography variant='h4'>
              <Trans id='Opening hours' />
            </Typography>
            <TableContainer component={Paper} sx={{ boxShadow: 'none' }}>
              <Table size='small'>
                <TableBody>
                  {opening_times?.map((time) => {
                    if (!time) return null

                    return (
                      <TableRow key={`${path}-${time.day_of_week}`}>
                        <TableCell sx={{ borderBottom: 0, pl: 0, pb: 0, pt: 0 }}>
                          <Typography variant='body1'>
                            {getWeekdayString(time.day_of_week)}
                          </Typography>
                        </TableCell>
                        {time.status ? (
                          <TableCell sx={{ borderBottom: 0, pr: 0, pb: 0, pt: 0 }}>
                            {time.lunch_start && time.lunch_stop ? (
                              <>
                                <Typography variant='body1'>
                                  {time.open} - {time.lunch_start}
                                </Typography>
                                <Typography variant='body1'>
                                  {time.lunch_stop} - {time.close}
                                </Typography>
                              </>
                            ) : (
                              <Typography variant='body1'>
                                {time.open} - {time.close}
                              </Typography>
                            )}
                          </TableCell>
                        ) : (
                          <TableCell sx={{ borderBottom: 0, pr: 0, pb: 0, pt: 0 }}>
                            <Typography variant='body1'>
                              <Trans id='Closed' />
                            </Typography>
                          </TableCell>
                        )}
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item lg={3} sm={6} xs={12}>
            <StoreLocatorMapLoader>
              <SimpleMapView />
            </StoreLocatorMapLoader>
          </Grid>
        </Grid>
      </Row>

      {longDescription && (
        <Box
          sx={(theme) => ({
            backgroundColor: theme.palette.background.default,
            py: theme.spacings.lg,
          })}
        >
          <Row sx={{ mb: 0 }}>
            <Typography
              variant='inherit'
              dangerouslySetInnerHTML={{ __html: longDescription }}
              component='div'
              sx={(theme) => ({
                columnCount: { md: 2 },
                columnGap: 5,
                '& a': { color: theme.palette.primary.main },
                '& p': { mt: 0 },
                '& h2, h3, h4': {
                  fontWeight: '600',
                  mb: 1,
                },
              })}
            />
          </Row>
        </Box>
      )}

      <Row sx={(theme) => ({ py: theme.spacings.lg, mb: 0 })}>
        <Typography variant='h4' sx={(theme) => ({ mb: theme.spacings.sm })}>
          <Trans id='Services' />
        </Typography>
        <ScrollerProvider>
          <Scroller
            hideScrollbar
            sx={(theme) => ({
              gridAutoColumns: '200px',
              height: '175px',
              gap: theme.spacings.xxs,
              '& .gezondheidscheck': {
                background: '#11AE4E !important',
              },
            })}
          >
            {services?.map((service) => {
              const iconName = service?.label?.toLowerCase().replace(/\s/g, '')
              if (!service) return null
              if (!iconName) return null

              return (
                <Box
                  key={service.label}
                  className={iconName}
                  sx={(theme) => ({
                    display: 'inline-flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '10px',
                    background: theme.palette.primary.main,
                    color: theme.palette.primary.contrastText,
                  })}
                >
                  <IconSvg src={iconNames[iconName] ?? iconCheckmark} sx={{ fontSize: '75px' }} />
                  <Typography fontWeight='600' variant='body1' sx={{ pt: 1 }}>
                    {service.label}
                  </Typography>
                </Box>
              )
            })}
          </Scroller>
        </ScrollerProvider>
      </Row>
    </Box>
  )
}

RetailStorePage.pageOptions = {
  Layout: LayoutNavigation,
} as PageOptions

export default RetailStorePage

export const getStaticPaths: GetPageStaticPaths = async ({ locales = [] }) => {
  if (import.meta.graphCommerce.limitSsg) return { paths: [], fallback: 'blocking' }

  const responses = locales.map(async (locale) => {
    const staticClient = graphqlSsrClient(locale)
    const RetailStoresPaths = staticClient.query({ query: RetailStoresPathsDocument })
    const { retailStores } = (await RetailStoresPaths).data
    return (
      retailStores?.map((retailStore) => ({ params: { url: `${retailStore?.path}` }, locale })) ??
      []
    )
  })
  const paths = (await Promise.all(responses)).flat(1)
  return {
    paths: process.env.VERCEL_ENV !== 'production' ? paths.slice(0, 1) : paths,
    fallback: 'blocking',
  }
}

export const getStaticProps: GetPageStaticProps = async ({ locale, params }) => {
  const urlKey = params?.url ?? '??'
  const client = graphqlSharedClient(locale)
  const staticClient = graphqlSsrClient(locale)

  const conf = client.query({ query: StoreConfigDocument })
  const page = staticClient.query({
    query: retailStoreDocument,
    variables: {
      id: `${urlKey}`,
      rootCategory: (await conf).data.storeConfig?.root_category_uid ?? '',
    },
  })

  const stores = staticClient.query({ query: GetRetailStoresDocument })
  const layout = staticClient.query({ query: LayoutDocument, fetchPolicy: 'cache-first' })

  if (!(await page).data.retailStore?.path) return { notFound: true }

  return {
    props: {
      ...(await page).data,
      ...(await stores).data,
      ...(await layout).data,
      up: { href: '/', title: 'Home' },
      apolloState: await conf.then(() => client.cache.extract()),
    },
    revalidate: 60 * 20,
  }
}
